import './App.css';
import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';

function TimeSensitiveText({ startTime, endTime, children  }) {
  const [shouldDisplayText, setShouldDisplayText] = useState(false);

  useEffect(() => {
    const currentHour = new Date().getHours();
    const isWithinTimeRange = currentHour >= startTime && currentHour < endTime;
    setShouldDisplayText(isWithinTimeRange);
  }, [startTime, endTime]);

  return shouldDisplayText ? children : null;
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <motion.h1
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity:1, y: 0 }}>
          Is Mark in the WaRz0n!!?
        </motion.h1>
        <div>
          <TimeSensitiveText startTime={0} endTime={7}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: 'spring', bounce: 0.6, delay: 1 }}
            >
              <p>💤 Shh, he might be sleeping 😴.</p>
            </motion.div>
          </TimeSensitiveText>
          <TimeSensitiveText startTime={7} endTime={15}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: 'spring', bounce: 0.6, delay: 1 }}
            >
              <p>No, he might be working ⏰</p>
            </motion.div>
          </TimeSensitiveText>
          <TimeSensitiveText startTime={15} endTime={24}>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: 'spring', bounce: 0.6, delay: 1 }}
            >
              <p class="huge">Yes 🎮</p>
              <a
                className="App-link"
                href="http://mbaddictionhelp.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn How to Help Here
              </a>
            </motion.div>
          </TimeSensitiveText>
        </div>
      </header>
    </div>
  );
}

export default App;
